var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var o7,p7,q7,ota,pta,qta,t7,u7,w7,x7,rta,sta,tta,z7,A7,B7,uta,vta;o7=function(a){var b=new $CLJS.h(null,3,[$CLJS.zD,$CLJS.p.h($CLJS.JD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.ni,$CLJS.zz($CLJS.ni,$CLJS.tA)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,b,$CLJS.zz($CLJS.rD,$CLJS.T)(a)],null)};p7=function(a){return $CLJS.R.j(a,$CLJS.Vs,$CLJS.E4)};q7=function(a,b){return $CLJS.E.g($CLJS.mD.h(a),$CLJS.Dl(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DD,$CLJS.lD,$CLJS.CD],null)))};
ota=function(a){return $CLJS.Q0(a,new $CLJS.h(null,1,[$CLJS.DD,$CLJS.mh],null))};
pta=function(a,b){var c=$CLJS.Me(function(e){return $CLJS.sG(function(f){return $CLJS.E.g($CLJS.K.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KY,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",c))){var d=$CLJS.iD("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.xh.l($CLJS.H([a])),$CLJS.xh.l($CLJS.H([$CLJS.hk.g($CLJS.KY,b)]))]));d instanceof Error?$CLJS.Kz("metabase.lib.field",c,$CLJS.xv(),d):$CLJS.Kz("metabase.lib.field",
c,$CLJS.xv.l($CLJS.H([d])),null)}return null};
qta=function(a,b,c){if($CLJS.n(r7))return null;var d=r7;r7=!0;try{var e=$CLJS.J1(a,b),f=$CLJS.n(e)?$CLJS.DV(a,e):$CLJS.DV(a,b),k=function(){var m=$CLJS.s7.h(f);if($CLJS.n(m))return m;m=$CLJS.Bz(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mU,$CLJS.AV],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.MU.h(f);if($CLJS.n(u))return u;u=$CLJS.dO.h(f);if($CLJS.n(u))return u;u=$CLJS.YO.h(f);return $CLJS.n(u)?u:$CLJS.TD.h(f)}())?$CLJS.Y_.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.Mz($CLJS.Ex);if($CLJS.n($CLJS.Lz("metabase.lib.field",
m))){var t=$CLJS.iD("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.Kz("metabase.lib.field",m,$CLJS.xv(),t):$CLJS.Kz("metabase.lib.field",m,$CLJS.xv.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?pta(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.k3($CLJS.Yj.l(l,$CLJS.Gi,$CLJS.H([$CLJS.ZJ,$CLJS.MV,$CLJS.HV])),null),$CLJS.T,function(){var m=$CLJS.KY.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.MJ,$CLJS.vY):l:null}finally{r7=d}};
t7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.K.g(e,$CLJS.sO);var f=$CLJS.I(c,2,null);c=$CLJS.xk.l($CLJS.H([function(){var k=$CLJS.tA.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.tA,k],null):null}(),function(){var k=$CLJS.zz($CLJS.ni,$CLJS.tA)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.ni,k],null):null}(),function(){var k=$CLJS.sQ.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.MV,k],null):null}(),function(){var k=$CLJS.rF.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.HV,k],null):null}(),$CLJS.Bd(f)?function(){var k=$CLJS.P1(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.KJ,$CLJS.T,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.KJ,$CLJS.T,$CLJS.p.h(f)],null):function(){var k=qta(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.KJ,$CLJS.T,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.k3(c,d):c};
u7=function(a){a=$CLJS.O(a);var b=$CLJS.K.g(a,$CLJS.HV);return $CLJS.n($CLJS.n(b)?$CLJS.Dd($CLJS.pG,b):b)?$CLJS.Yi:$CLJS.zz($CLJS.ni,$CLJS.tA)(a)};
$CLJS.v7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.K.g(f,$CLJS.zD),l=$CLJS.K.g(f,$CLJS.tA),m=$CLJS.K.g(f,$CLJS.sQ),t=$CLJS.K.g(f,$CLJS.ni);e=$CLJS.K.g(f,$CLJS.sO);var u=$CLJS.K.g(f,$CLJS.DM),v=$CLJS.K.g(f,$CLJS.rF),x=$CLJS.xk.l;k=new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.KJ,$CLJS.B0,k],null);f=$CLJS.nD.h(f);a=$CLJS.n(f)?f:$CLJS.l_.j(a,b,d);c=x.call($CLJS.xk,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.nD,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.ni,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.tA,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.HV,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.MV,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.YJ,u):m;return $CLJS.n(e)?$CLJS.k3(u,e):u};w7=function(a,b,c){return $CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.di.h(d),c)},a))?$CLJS.hk.g(function(d){var e=$CLJS.Dd(d,b)?$CLJS.Yj.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.di.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
x7=function(a){var b=$CLJS.n($CLJS.q1.h(a))?null:function(){var e=$CLJS.MJ.h(a),f=new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.OY,null,$CLJS.VY,null,$CLJS.vY,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.xk.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.zD,$CLJS.p.h($CLJS.JD()),$CLJS.tA,$CLJS.tA.h(a),$CLJS.ni,u7(a)],null),function(){var e=function(){var f=$CLJS.Ra(b);return f?(f=$CLJS.Ra($CLJS.YJ.h(a)))?(f=$CLJS.ek.g($CLJS.GY,$CLJS.MJ.h(a)))?$CLJS.ZY.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.sO,e],null):null}(),function(){var e=$CLJS.j1(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.sO,e],null):null}(),function(){var e=$CLJS.HV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.rF,e],null):null}(),function(){var e=$CLJS.LV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.LV,e],null):null}(),function(){var e=$CLJS.MV.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.sQ,e],null):null}(),function(){var e=$CLJS.YJ.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.DM,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.zz($CLJS.KY,$CLJS.T):$CLJS.zz($CLJS.Gi,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lF,c,d],null)};rta=function(a,b){return $CLJS.ff(function(c){return $CLJS.E.g($CLJS.MJ.h(c),$CLJS.EY)},$CLJS.Y_.v(a,b,$CLJS.DV(a,b),new $CLJS.h(null,3,[$CLJS.F_,!1,$CLJS.L_,!0,$CLJS.B_,!1],null)))};sta=new $CLJS.M("lib","simple-name","lib/simple-name",-734346338);tta=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.y7=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);z7=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);A7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.s7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);B7=new $CLJS.M("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
uta=new $CLJS.M(null,"earliest","earliest",-1928154382);vta=new $CLJS.M(null,"latest","latest",24323665);$CLJS.R0.m(null,$CLJS.lF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mh.h(b),$CLJS.Q0(c,new $CLJS.h(null,2,[$CLJS.rF,$CLJS.mh,$CLJS.sQ,ota],null)),a],null)});
var r7=!1,C7=function C7(a,b){var d=$CLJS.$0(a,A7.h(b));a=$CLJS.n(A7.h(d))?C7.g?C7.g(a,d):C7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.K.g(a,$CLJS.T),f=$CLJS.K.g(a,$CLJS.nD);return $CLJS.ik.j($CLJS.R.j($CLJS.ik.j($CLJS.R.j(b,sta,$CLJS.T.h(b)),$CLJS.T,function(k){return[$CLJS.p.h(e),".",$CLJS.p.h(k)].join("")}),B7,$CLJS.nD.h(b)),$CLJS.nD,function(k){return[$CLJS.p.h(f),": ",$CLJS.p.h(k)].join("")})};$CLJS.Q_.m(null,$CLJS.KJ,function(a,b,c){return u7(c)});
$CLJS.Q_.m(null,$CLJS.lF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.K.g(d,$CLJS.rF);$CLJS.I(c,2,null);c=t7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.HV,d):c;return $CLJS.R_.j(a,b,c)});$CLJS.S_.m(null,$CLJS.KJ,function(a,b,c){a=$CLJS.O(c);b=$CLJS.K.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.S_.m(null,$CLJS.lF,function(a,b,c){var d=t7(a,b,c);b=$CLJS.v7(a,b,d,c);return $CLJS.n(A7.h(b))?C7(a,b):b});
$CLJS.M_.m(null,$CLJS.KJ,function(a,b,c,d){function e(W){var da=$CLJS.k5(l,k);return $CLJS.ha.j?$CLJS.ha.j("%s: %s",W,da):$CLJS.ha.call(null,"%s: %s",W,da)}function f(W){var da=$CLJS.cA($CLJS.Gz($CLJS.Xg(u),"-"," "));return $CLJS.ha.j?$CLJS.ha.j("%s: %s",W,da):$CLJS.ha.call(null,"%s: %s",W,da)}var k=$CLJS.O(c),l=$CLJS.K.g(k,$CLJS.MV),m=$CLJS.K.g(k,$CLJS.YJ),t=$CLJS.K.g(k,$CLJS.ZJ),u=$CLJS.K.g(k,$CLJS.di),v=$CLJS.K.g(k,$CLJS.ZY),x=$CLJS.K.g(k,B7),A=$CLJS.K.g(k,$CLJS.T),C=$CLJS.K.g(k,$CLJS.K5),G=$CLJS.K.g(k,
$CLJS.nD),J=$CLJS.K.g(k,A7),S=$CLJS.h1.g($CLJS.xA,A);c=function(){if($CLJS.n(x))return x;if($CLJS.n($CLJS.n(J)?null==G||$CLJS.E.g(G,S):J)){a:{var W=$CLJS.O(k);var da=$CLJS.K.g(W,$CLJS.nD);W=$CLJS.K.g(W,A7);for(da=new $CLJS.Vd(null,da,null,1,null);;)if($CLJS.n(W)){W=$CLJS.$0(a,W);var ra=$CLJS.O(W);W=$CLJS.K.g(ra,$CLJS.nD);ra=$CLJS.K.g(ra,A7);da=$CLJS.Yd.g(da,W);W=ra}else break a}da=$CLJS.Le($CLJS.Sa,da)?$CLJS.hs(": ",da):null}else da=null;return $CLJS.n(da)?da:$CLJS.n(G)?G:"string"===typeof A?S:$CLJS.p.h(A)}();
var X=$CLJS.E.g(d,$CLJS.m_)&&-1==c.indexOf(" → ")?function(){if($CLJS.n(m)){var W=$CLJS.$0(a,m);$CLJS.n(W)?W=$CLJS.g3($CLJS.nD.h($CLJS.o_.j(a,b,W))):(W=$CLJS.i1(a,t),W=$CLJS.l_.v(a,b,W,d))}else W=null;return $CLJS.n(W)?W:$CLJS.n(v)?v:$CLJS.j1(k)}():null;c=$CLJS.n(X)?[$CLJS.p.h(X)," → ",$CLJS.p.h(c)].join(""):c;return $CLJS.n(function(){var W=$CLJS.ek.g(d,$CLJS.m_);return W?C:W}())?c:$CLJS.n($CLJS.n(u)?$CLJS.ek.g(c,f(S)):u)?f(c):$CLJS.n($CLJS.n(l)?$CLJS.ek.g(c,e(S)):l)?e(c):c});
$CLJS.M_.m(null,$CLJS.lF,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.K.g(f,$CLJS.sQ);var k=$CLJS.K.g(f,$CLJS.sO),l=$CLJS.K.g(f,$CLJS.rF);f=$CLJS.K.g(f,$CLJS.DM);$CLJS.I(c,2,null);c=t7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.ZY,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.di,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.MV,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.YJ,f):e;return $CLJS.n(e)?$CLJS.l_.v(a,b,e,d):$CLJS.KD("[Unknown Field]")});
$CLJS.N_.m(null,$CLJS.KJ,function(a,b,c){a=$CLJS.O(c);return $CLJS.K.g(a,$CLJS.T)});$CLJS.N_.m(null,$CLJS.lF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=t7(a,b,c);return $CLJS.n(c)?$CLJS.O_.j(a,b,c):"unknown_field"});
$CLJS.T_.m(null,$CLJS.KJ,function(a,b,c){return $CLJS.xk.l($CLJS.H([function(){var d=$CLJS.lH($CLJS.T_,$CLJS.Oh);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.l_.j(a,b,c),$CLJS.n_,$CLJS.l_.v(a,b,c,$CLJS.m_)],null),$CLJS.E.g($CLJS.MJ.h(c),$CLJS.OY)?function(){var d=$CLJS.YY.h(c);return $CLJS.n(d)?(d=$CLJS.f_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.Kj,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.nD,$CLJS.T.h(d)],null)],null):null):null}():null]))});
$CLJS.u0.m(null,$CLJS.lF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.rF.h(b)});$CLJS.u0.m(null,$CLJS.KJ,function(a){return $CLJS.HV.h(a)});
$CLJS.t0.m(null,$CLJS.lF,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Dd($CLJS.pG,b),e=$CLJS.qB($CLJS.LV,$CLJS.ni,$CLJS.tA)(c);c=$CLJS.R.l(c,$CLJS.rF,b,$CLJS.H([$CLJS.ni,d?$CLJS.Yi:e,$CLJS.LV,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lF,c,a],null)}b=$CLJS.LV.h(c);c=$CLJS.n(b)?$CLJS.Yj.g($CLJS.R.j(c,$CLJS.ni,b),$CLJS.LV):c;c=$CLJS.Yj.g(c,$CLJS.rF);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lF,c,a],null)});
$CLJS.t0.m(null,$CLJS.KJ,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.HV,b,$CLJS.H([$CLJS.LV,$CLJS.qB($CLJS.LV,$CLJS.ni,$CLJS.tA)(a)])):$CLJS.Yj.l(a,$CLJS.HV,$CLJS.H([$CLJS.LV]))});$CLJS.w0.m(null,$CLJS.lF,function(a,b,c){return $CLJS.x0.j(a,b,t7(a,b,c))});
$CLJS.w0.m(null,$CLJS.KJ,function(a,b,c){if($CLJS.ek.g($CLJS.MJ.h(c),$CLJS.EY)){a=$CLJS.zz($CLJS.ni,$CLJS.tA)(c);b=null==c?null:$CLJS.RM.h(c);if(null==b)var d=null;else try{var e=$CLJS.Hk.h($CLJS.Ri.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.K.g(f,uta),l=$CLJS.K.g(f,vta),m=$CLJS.kpa.l($CLJS.H([$CLJS.r0.h(k),$CLJS.r0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Cq.g?$CLJS.Cq.g(1,m):$CLJS.Cq.call(null,1,m))?$CLJS.Fk:$CLJS.n($CLJS.Cq.g?$CLJS.Cq.g(31,m):$CLJS.Cq.call(null,31,m))?$CLJS.Lk:$CLJS.n($CLJS.Cq.g?
$CLJS.Cq.g(365,m):$CLJS.Cq.call(null,365,m))?$CLJS.Ti:$CLJS.Zi}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.Dz(a,$CLJS.Hk)?$CLJS.vpa:$CLJS.Dz(a,$CLJS.Dk)?$CLJS.upa:$CLJS.Dz(a,$CLJS.Mk)?$CLJS.tpa:$CLJS.xf;d=$CLJS.n(d)?w7(e,$CLJS.Oh,d):e;return $CLJS.n($CLJS.HV.h(c))?w7(d,$CLJS.t_,$CLJS.HV.h(c)):d}return $CLJS.xf});
$CLJS.h5.m(null,$CLJS.lF,function(a){var b=null==a?null:$CLJS.GD(a);b=null==b?null:$CLJS.sQ.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.m5,$CLJS.H([$CLJS.l5,function(c,d){return t7(c,d,a)}]))});$CLJS.h5.m(null,$CLJS.KJ,function(a){var b=null==a?null:$CLJS.MV.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Vs,$CLJS.m5,$CLJS.H([$CLJS.l5,$CLJS.Pe(a)]))});$CLJS.n5.m(null,$CLJS.lF,function(a,b){return $CLJS.HD(a,$CLJS.FD,$CLJS.H([$CLJS.sQ,b]))});
$CLJS.n5.m(null,$CLJS.KJ,function(a,b){return $CLJS.FD(a,$CLJS.MV,b)});$CLJS.o5.m(null,$CLJS.lF,function(a,b,c){return $CLJS.p5.j(a,b,t7(a,b,c))});
$CLJS.o5.m(null,$CLJS.KJ,function(a,b,c){b=$CLJS.O(c);c=$CLJS.K.g(b,$CLJS.ni);var d=$CLJS.K.g(b,$CLJS.RM),e=$CLJS.K.g(b,$CLJS.rj);if($CLJS.ek.g($CLJS.MJ.h(b),$CLJS.EY)){var f=function(){var m=null==a?null:$CLJS.Z0(a);m=null==m?null:$CLJS.dz.h(m);return null==m?null:$CLJS.Dd(m,$CLJS.sQ)}(),k=$CLJS.Bz(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,$CLJS.nj],null)),l=$CLJS.i5(b);return function u(t){return new $CLJS.ie(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.wd(v)){var x=$CLJS.ic(v),A=$CLJS.D(x),
C=$CLJS.le(A);return function(){for(var J=0;;)if(J<A){var S=$CLJS.id(x,J),X=C,W=S;S=q7(S,l)?$CLJS.R.j(W,$CLJS.t_,!0):W;X.add(S);J+=1}else return!0}()?$CLJS.oe($CLJS.qe(C),u($CLJS.jc(v))):$CLJS.oe($CLJS.qe(C),null)}var G=$CLJS.z(v);return $CLJS.ae(function(){var J=G;return q7(G,l)?$CLJS.R.j(J,$CLJS.t_,!0):J}(),u($CLJS.Hc(v)))}return null}},null,null)}($CLJS.Ra(function(){if($CLJS.n(f)){var t=$CLJS.ri.h(k);return $CLJS.n(t)?$CLJS.Aj.h(k):t}return f}())?null:$CLJS.Dz(e,$CLJS.$i)?$CLJS.hk.g(p7,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.j5(),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.KD("Bin every 0.1 degrees"),$CLJS.mD,new $CLJS.h(null,2,[$CLJS.DD,$CLJS.CD,$CLJS.CD,.1],null)],null),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.KD("Bin every 1 degree"),$CLJS.mD,new $CLJS.h(null,2,[$CLJS.DD,$CLJS.CD,$CLJS.CD,1],null)],null),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.KD("Bin every 10 degrees"),$CLJS.mD,new $CLJS.h(null,2,[$CLJS.DD,$CLJS.CD,$CLJS.CD,10],null)],null),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.KD("Bin every 20 degrees"),$CLJS.mD,new $CLJS.h(null,
2,[$CLJS.DD,$CLJS.CD,$CLJS.CD,20],null)],null)],null)):$CLJS.Dz(c,$CLJS.nj)&&!$CLJS.Dz(e,$CLJS.$h)?$CLJS.hk.g(p7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.j5(),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.KD("10 bins"),$CLJS.mD,new $CLJS.h(null,2,[$CLJS.DD,$CLJS.lD,$CLJS.lD,10],null)],null),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.KD("50 bins"),$CLJS.mD,new $CLJS.h(null,2,[$CLJS.DD,$CLJS.lD,$CLJS.lD,50],null)],null),new $CLJS.h(null,2,[$CLJS.nD,$CLJS.KD("100 bins"),$CLJS.mD,new $CLJS.h(null,2,[$CLJS.DD,$CLJS.lD,$CLJS.lD,
100],null)],null)],null)):null)}return $CLJS.xf});$CLJS.mV.m(null,$CLJS.lF,function(a){return a});
$CLJS.mV.m(null,$CLJS.KJ,function(a){a=$CLJS.O(a);var b=$CLJS.K.g(a,$CLJS.MJ);switch(b instanceof $CLJS.M?b.S:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.zD,$CLJS.p.h($CLJS.JD()),$CLJS.ni,$CLJS.zz($CLJS.ni,$CLJS.tA)(a),$CLJS.zK,$CLJS.T.h(a)],null),a=$CLJS.B0.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aF,b,a],null);case "source/expressions":return o7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.rD.h(a))?o7(a):x7(a);default:return x7(a)}});
$CLJS.wta=function(){function a(e,f,k){k=$CLJS.Be($CLJS.hk.g($CLJS.oV,k));var l=rta(e,f),m=$CLJS.Wf.j($CLJS.Sg,$CLJS.vl(function(t){return $CLJS.V1.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.xf);m=$CLJS.gk.g(m,l);k=$CLJS.n(k)?$CLJS.Wf.j(k,$CLJS.cf.h($CLJS.oV),m):null;return $CLJS.OV.l(e,f,$CLJS.FD,$CLJS.H([$CLJS.TD,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.D7=function(){function a(d,e){return $CLJS.TD.h($CLJS.DV(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.xta=function(){function a(d,e){var f=$CLJS.Y_.v(d,e,$CLJS.DV(d,e),new $CLJS.h(null,3,[$CLJS.F_,!1,$CLJS.L_,!1,$CLJS.B_,!1],null)),k=$CLJS.D7.g(d,e);return $CLJS.pd(k)?$CLJS.hk.g(function(l){return $CLJS.R.j(l,$CLJS.MY,!0)},f):$CLJS.i3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Y(z7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dj,$CLJS.cj,$CLJS.yY,$CLJS.cz],null));
$CLJS.Y(tta,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.y7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,z7],null)],null)],null));